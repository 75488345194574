.board {
  float: left;
  position: relative;
  max-width: 300px;
  height: auto;
  margin: 8px;
  background-color: lightgray;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.drop-card {
  float: left;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 10px;
  margin: 4px 0;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  cursor: move;
  cursor: grab;
  header {
    float: left;
    width: 100%;
    padding: 0.5em;
    background-color: lightgray;
    text-transform: uppercase;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px 3px 0 0;
  }
}
.drop-cardtitle {
  float: left;
  width: 100%;
}
.noselect {
  //Instead of the line below you could use @include user-select($select)
  user-select: none;
}
.gu-mirror {
  cursor: move;
  cursor: grabbing;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 1;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: rotate(5deg);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  //Instead of the line below you could use @include user-select($select)
  user-select: none !important;
}
.gu-transit {
  opacity: 0.4;
  filter: alpha(opacity=20);
}
#board1 header {
  background-color: #bc3425;
  color: white;
}
#board2 header {
  background-color: #92549e;
  color: white;
}
#board3 header {
  background-color: #00b1cf;
  color: white;
}
.drop-cards .is-moving {
  background-color: white;
  color: black;
}
.cards {
  padding: 4px 8px;
}
